<template>
  <div id="PlanoContaModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="text-white title-page body-1">Cadastro de etapa</span>
          <v-btn
            icon
            dark
            color="#F2F6F7"
            @click="fechar()">
            <v-icon large class="title">
              mdi-close
            </v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="pt-1 mb-6">
        <v-container>
          <v-form 
            class="form-container"
            ref="form"
            v-model="valid">
            <v-row>
              <v-col 
                cols="12"
                class="px-0">
                <v-autocomplete
                  v-model="dados.item_pai"
                  :items="opcoes_etapas"
                  label="Etapa pai"
                  placeholder="Etapa pai"
                  item-text="text"
                  item-key="cod_orcamento_etapa"
                  autofocus
                  filled 
                  outlined 
                  clearable
                  return-object
                  dense 
                  @change="changeItemCadastrado()"
                  background-color="#FFF" 
                  required
                  :key="key_Pai">
                  <template v-slot:selection="data">
                    <span>
                      {{ data.item.text.length > 35 ? data.item.text.substring(0, 35) + '...' : data.item.text }}
                    </span>
                  </template>
                  <template v-slot:item="data">
                    <span :style="{'margin-left': (data.item.codigo.split('.').length-1)*20+'px'} ">
                      {{ data.item.text.length > 35 ? data.item.text.substring(0, 35) + '...' : data.item.text }}
                    </span>
                  </template>
                </v-autocomplete>
                <div>
                  <!-- Código -->
                  <v-text-field
                    v-if="this.dados_editar.acao == 'editar'"
                    readonly
                    v-model="dados.codigo"
                    class="mb-0 "
                    light
                    background-color="#d0d0d0"
                    label="Código"
                    placeholder="Código"
                    filled
                    maxlength="100"
                    outlined
                    required
                    dense >
                  </v-text-field>
                  <!-- Campo Nome da etapa -->
                  <v-text-field
                    v-model="dados.nome_etapa"
                    class="mb-0 "
                    light
                    background-color="#FFF"
                    label="Nome da etapa"
                    placeholder="Nome da etapa"
                    filled
                    clearable
                    :rules="descricaoRules"
                    maxlength="100"
                    outlined
                    required
                    dense />

                  <!-- Campo Data de início (previsão) -->
                  <DatePicker
                    v-model="dados.dt_previsao_inicio"
                    class="mb-0 "
                    light
                    background-color="#FFF"
                    label="Data de início (previsão)"
                    placeholder="Data de início (previsão)"
                    filled
                    maxlength="100"
                    outlined
                    required
                    dense />

                  <!-- Campo Data de término (previsão) -->
                  <DatePicker
                    v-model="dados.dt_previsao_termino"
                    class="mb-0 "
                    light
                    background-color="#FFF"
                    label="Data de término (previsão)"
                    placeholder="Data de término (previsão)"
                    filled
                    maxlength="100"
                    outlined
                    required
                    :rules="dataRules"
                    dense />

                  <!-- Campo Custo total (previsão) -->
                  <VuetifyMoney
                    v-model="dados.custo_total_previsto"
                    class="mb-0 "
                    light
                    background-color="#FFF"
                    label="Custo total (previsão)"
                    placeholder="Custo total (previsão)"
                    filled
                    clearable
                    maxlength="21"
                    outlined
                    required
                    dense />
                </div>
              </v-col>
            </v-row>
              
            </v-form>
        </v-container>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="fechar()"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text>
          Cancelar
        </v-btn>

        <v-btn
          :disabled="!valid"
          :loading="loading"
          @click="validate()"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import DatePicker from "../../../../ComponentesGlobais/Date-Picker/date-picker.vue";
import VuetifyMoney from "../../../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
//  "@/components/ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import store_site from "../../../../../store/store_site"
import store_usuario from "../../../../../store/store_usuario"
import { API } from '../../../../../services/API'

import { COR_PRINCIPAL, arraySIM_NAO } from "../../../../../services/constantes";
import { nextTick } from 'vue';

export default {
  name: "PlanoContaModal",

    components: {
    DatePicker,
    VuetifyMoney
    },

  // props: [],
  props: {
    array_etapas: { type: Array },
    dados_editar: { type : Object }
  },

  data() {
    return {
      store_site        : store_site,
      store_usuario     : store_usuario,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      loading           : false,
      search            : null,
      arraySIM_NAO      : arraySIM_NAO,
      valid: true,
      form: false,
      key_Pai: 0,
      codigocontabil_aux : null,
      opcoes_etapas : [],
      dados : {
        item_pai                      : null,
        codigo                        : null,
        nome_etapa                    : null,
        dt_previsao_inicio            : null,
        dt_previsao_termino           : null,
        custo_total_previsto          : null,
      },
   
      descricaoRules: [
        (value) => !!value || "Preenchimento obrigatório",
      ],
      dataRules: [
        (value) => this.dados.dt_previsao_inicio <= this.dados.dt_previsao_termino || "O Intervalo de datas é inválido"
      ],

      // codigoRules: [
      //   (value) => !!value || "O Código Contábil é obrigatório",
      //   (value) => (/^\d+$/.test(value)) || "Digite apenas números"
      // ],
      headers: [
        { text : '', value:'' },
        { text : 'Cód. contabil', value:'cod_contabil' },
        { text : 'Descrição', value:'descricao' },
      ],
    
    };
  },

  created() {
    //console.log('created', this.$route.params.cod_obra);
  },

  computed: {
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    }
  },


  async mounted(){
    this.opcoes_etapas = [...this.array_etapas]
    
    if(this.dados_editar?.acao == 'editar'){
      this.dados = {...this.dados_editar}
      let i = this.opcoes_etapas.findIndex(val => val.cod_orcamento_etapa == this.dados.cod_orcamento_etapa)

      if(i){
        this.opcoes_etapas.splice(i, 1)
      }

    if (this.dados.cod_orcamento_etapa_rel) {
      const pai = await this.achandoPai()
      this.dados.item_pai = pai;
      this.key_Pai++;
    }

      // let codigocontabil = this.dados_editar.codigo.split('.')
      // this.dados.codigo = codigocontabil[codigocontabil.length -1]
      // this.codigocontabil_aux = this.dados?.item_pai?.codigo 
    }
  },

  methods: {

    changeItemCadastrado(){
      // console.log('item pai', this.dados.item_pai)
      this.dados.cod_orcamento_etapa_rel = this.dados.item_pai.cod_orcamento_etapa
    },

    async achandoPai(){
      var la_achando_pai = this.dados.codigo.split('.')
      var pai_encontrado = ''
      for (let index = 0; index < la_achando_pai.length -1; index++) {
        const element = la_achando_pai[index];
        pai_encontrado += element + '.'
      }
      pai_encontrado = pai_encontrado.slice(0, -1);
      
      var lo_item_cadastrado = await this.opcoes_etapas.find(val => val.codigo === pai_encontrado)
      if(!lo_item_cadastrado){
        lo_item_cadastrado = this.opcoes_etapas[0]
      }
      return lo_item_cadastrado
    },

    fechar(){
      this.$emit("update:dialog_PlanoContaModal", false);
    },

    async validate () {
      const lb_valido = this.$refs.form.validate();
      
      if (lb_valido) {
       
        this.loading = true
          
        this.dados = {...this.dados} 

        let ls_Rel          = null
        let li_CodOrcamento = null;
        if(this.dados?.acao === 'editar'){
          ls_Rel = await API.put(`/obras/orcamentos/etapa`, JSON.stringify(this.dados));
          if (this.array_etapas.length > 0)
            li_CodOrcamento = this.array_etapas[0].cod_orcamento;
        } 
        else {
          if (this.array_etapas.length > 0)
            li_CodOrcamento = this.array_etapas[0].cod_orcamento;
          this.dados.codigo         = `${this.dados.item_pai?.codigo}`
          this.dados.cod_empresa    = this.store_usuario.user.cod_empresa;
          this.dados.cod_obra       = this.$route.params.cod_obra;
          this.dados.cod_orcamento  = li_CodOrcamento;
          ls_Rel = await API.post(`/obras/orcamentos/etapa`, JSON.stringify(this.dados));
          li_CodOrcamento = ls_Rel.data.result.data.cod_orcamento;
        }

        // console.log(ls_Rel)
        this.loading = false
        this.fechar()
        
        if (ls_Rel.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Rel.data.result.result;
          this.store_site.alert           = true;
          this.$emit('busca', li_CodOrcamento)
        } else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = ls_Rel.data.result.result.trim();
          this.store_site.alert           = true;
        }
      }
    }
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  /* height: 450px; */
  overflow-y: auto;
}

.btn {
  width: 100px;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 
</style>