<template>
  <div id="OrcamentoEtapa" class="container-fundo">

    <v-card width="100%" outlined>
      <v-card-title>
        <!-- Botão Nova Etapa -->
        <v-btn
          v-on:click="novo()"
          class="text-white text-capitalize "
          color="green"
          elevation="0"
          small>
          <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
          <span>Nova Etapa</span>
        </v-btn>
      </v-card-title>

      <!-- Tabela ------------------------------------>
      <v-row class=" w-100 mx-0">
        <!-- Grade ----------------------------------->
        <v-col cols="12" class="pa-0 mx-0 w-100">
          <v-container class="container-principal container-rounded px-0 w-100 mx-0">
            <v-form ref="form">
            <!-- <pre>{{ array_etapas }}</pre> -->
            <v-data-table
              :headers="headers"
              :items="array_etapas ? array_etapas : []"
              fixed-header
              hide-default-footer
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta"
              no-results-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta`"
              item-key="cod_orcamento_etapa"
              no-sorting
              :loading="loading"
              :search="search"
              :items-per-page="-1"
            >
              <!-- <template #item="{ item }"> -->
                <template v-slot:body="props">
                  <draggable
                    :key="props.items.cod_orcamento_etapa"
                    :list="props.items"
                    tag="tbody"
                    handle=".page__grab-icon"
                    :move="checkMove"
                    @end="onReorder"
                  >
                <tr
                  v-for="(item, index) in array_etapas"
                  :key="index"
                  :style="{'background-color' : 'rgba(0, 50, 100,'+(0.27-(item.codigo.split('.').length)/10)+')'}"                  
                  @mouseover="hover = index"
                  @mouseleave="hover = null"
                  >
                  <td
                    style="width: 100px!important">
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          v-for="(item_menu, i) in items"
                          :key="i"
                          @click="() => {}"
                          class="px-1"
                        >
                        
                          <v-list-item-title
                            v-if="item_menu.title == 'Editar'"
                            @click="editar(item)">                          
                              <v-icon
                                color="green"
                                class="mr-2 icon-menu">
                                mdi-pencil-outline
                              </v-icon>
                            {{ item_menu.title }}
                          </v-list-item-title>

                          <v-list-item-title
                            v-else-if="item_menu.title == 'Excluir'">
                            <div
                              @click="dialogExcluir(item)">
                              <v-icon
                                color="red"
                                class="mr-2 icon-menu">
                                mdi-delete-outline
                              </v-icon>
                              {{ item_menu.title }}
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                  <v-icon
                    v-if="hover == index"
                    @mouseover="isHovering = index"
                    @mouseleave="isHovering = null"
                    color="#00D000"
                    class="ml-2 custom-button-move z-index-max"
                    :class="{'page__grab-icon': true, 'mdi-arrow-all': isHovering === index}"
                    style="cursor: grab;"
                  >
                    mdi-arrow-all
                  </v-icon>
                  <div v-else>
                  </div>
                    
                  </td>
                  <!-- Campo Código -->
                  <td>
                    <span
                      :style="{'font-weight'      : item.cod_orcamento_etapa_rel ? '' : 'bold',
                               'font-style'       : 'weight ',
                               'font-size'        : item.cod_orcamento_etapa_rel ? '14px' : '15px', 
                               'color'            : 'rgba(0, 50, 100, 1)',
                               'margin-left'      : (item.codigo.split('.').length-1)*20-10+'px' }">
                      {{item?.codigo}} - {{ item.nome_etapa }}
                    </span>
                  </td>
                  <!-- Campo Previsão -->
                <td>
                  <div>
                    <span
                      class="d-flex align-center mb-1"
                      style="font-size: 12px; color: #808080 !important"
                    >
                      <span style="font-weight: bold" class="mr-5"
                        >Início:
                      </span>
                      {{
                        item.dt_previsao_inicio
                          ? formatDate(item.dt_previsao_inicio)
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="d-flex align-center"
                      style="font-size: 12px; color: #808080 !important"
                    >
                      <span style="font-weight: bold" class="mr-1"
                        >Término:
                      </span>
                      {{
                        item.dt_previsao_termino
                          ? formatDate(item.dt_previsao_termino)
                          : ""
                      }}
                    </span>
                  </div>
                </td>
                  <!-- Campo Realização -->
                <td>
                  <div>
                    <span
                      class="d-flex align-center mb-1"
                      style="font-size: 12px; color: #808080 !important"
                    >
                      <span style="font-weight: bold" class="mr-5"
                        >Início:
                      </span>
                      {{
                        item.dt_realizacao_inicio
                          ? formatDate(item.dt_realizacao_inicio)
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="d-flex align-center"
                      style="font-size: 12px; color: #808080 !important"
                    >
                      <span style="font-weight: bold" class="mr-1"
                        >Término:
                      </span>
                      {{
                        item.dt_realizacao_termino
                          ? formatDate(item.dt_realizacao_termino)
                          : ""
                      }}
                    </span>
                  </div>
                </td>
                  <!-- Campo Custo total -->
                <td>
                  <div>
                    <span
                      class="d-flex align-center mb-1"
                      style="font-size: 12px; color: #808080 !important"
                    >
                      <span style="font-weight: bold" class="mr-2"
                        >Previsto:
                      </span>
                      {{
                        item.custo_total_previsto
                          ? formatNumber(item.custo_total_previsto)
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="d-flex align-center"
                      style="font-size: 12px; color: #808080 !important"
                    >
                      <span style="font-weight: bold" class="mr-1"
                        >Realizado:
                      </span>
                      {{
                        item.custo_total_realizado
                          ? formatNumber(item.custo_total_realizado)
                          : ""
                      }}
                    </span>
                  </div>
                </td>
                </tr>
                  </draggable>
            </template>
          </v-data-table>
          </v-form>
          </v-container>
        </v-col>
        <!-- Fim Grade ----------------------------------->
      </v-row>
    </v-card>

        <!-- Dialog Excluir ----------------------- -->
        <v-dialog
          :value="dialog_excluir"
          persistent
          max-width="430"
          class="d-flex justify-center">
        <v-divider></v-divider>

        <v-card elevation="0" class="d-flex justify-center">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
              <span class="white--text title-page body-1">Excluir etapa</span>
                <v-btn @click="dialog_excluir = false" icon dark color="#F2F6F7">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
          <v-card-title class="text-h6">
            Tem certeza que deseja EXCLUIR ?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir = false">
              Cancelar
            </v-btn>

            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="excluir()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Novo/Editar -->
      <v-container v-if="dialog_PlanoContaModal">
        <v-dialog
          persistent
          v-model="dialog_PlanoContaModal"
          transition="dialog-bottom-transition"
          max-width="500">
          <ModalCadastrarEtapa
            :dialog_PlanoContaModal.sync="dialog_PlanoContaModal"
            :array_etapas="array_etapas"
            :dados_editar="dados_editar"
            @busca="grade"/>
        </v-dialog>
      </v-container>

<!-- <pre>{{ array_etapas }}</pre> -->

  </div>
</template>

<script>
import Vue from 'vue';
import {
  formatNumber,
  formatDate,
} from "../../../../services/funcoes";

import ModalCadastrarEtapa from "./ModalOrcamento/ModalCadastrarEtapa.vue";
import { baseURL, API } from "../../../../services/API";
import store_usuario from "../../../../store/store_usuario";
import store_Orcamento from "./store_Orcamento";
import store_site from "../../../../../src/store/store_site"
import store_Empreendimento from "../../../Empreendimentos/store_Empreendimento";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO, COR_BORDA } from "../../../../services/constantes";
import moment, { now } from 'moment';
import { saveAs } from 'file-saver';
import { AbreRelatorioExterno, Relatorio } from "../../../../services/global";
import exportFromJSON from "export-from-json";
import Draggable from 'vuedraggable';
//---------------------- fim teste download excel --------

export default {
  name: "OrcamentoEtapa",

  props: ['acao', 
          'dialog_ContasPagar', 
          'itens_array_pai', 
          'parc_valor', 
          'parc_vencto', 
          'dialog_lancamentos', 
          'cedente_selecionado',
          'cod_orcamento'],

  components: {
    ModalCadastrarEtapa,
    Draggable
  },

  data() {
    return {
      formatDate: formatDate,
      formatNumber: formatNumber,

      user                    : { username : null }, 

      array_etapas            : [],
      search                  : '',
      dialog_PlanoContaModal  : false,
      dados_editar            : null,

      hover                   : null,
      isHovering              : null,

      store_usuario                        : store_usuario,
      store_Orcamento                    : store_Orcamento,
      store_site                           : store_site,
 
      loading                              : false,
  
      COR_PRINCIPAL                        : COR_PRINCIPAL,
      COR_SECUNDARIA                       : COR_SECUNDARIA,
      COR_SECUNDARIA_2                     : COR_SECUNDARIA_2, 
      COR_SUBTITULO                        : COR_SUBTITULO,
      COR_BORDA                            : COR_BORDA,
      accentColor                          : '#f00',
 
      dialog_excluir                       : false,
     
      /* Título (nomes das colunas da tabela) */
      headers: [
        { text: "", value: "", style: "", sortable: false, },
        // { text: "Código", value: "codigo", style: "", sortable: false, },
        { text: "Nome", value: "planoconta_descricao", style: "", sortable: false, },
        { text: "Previsão", value: "planoconta_situacao", style: "", sortable: false, },
        { text: "Realização", value: "planoconta_analiticasintetica", style: "", sortable: false, },
        { text: "Custo total", value: "planoconta_creditodebito", style: "", sortable: false, },
      ],
      /* Menu Edição (Button Dots) */
      items: [
      {
        title: "Editar",
      },
      {
        title: "Excluir", 
      },
      ],
    };
  },

  created() {
    this.grade(this.cod_orcamento);
  },

  mounted() {
    //this.grade()
    // Seta os privilegios dos elementos da apícãõ inteira
    //this.store_usuario.priv_seta_privilegios_aplicacao(this.$options.name);
  },
  
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },
  },

  methods: {
    dialogExcluir(item){

      // var la_achando_pai = item.codigo.split('.')
      // var pai_encontrado = ''
      // for (let index = 0; index < la_achando_pai.length -1; index++) {
      //   const element = la_achando_pai[index];
      //   pai_encontrado += element + '.'
      // }
      // pai_encontrado = pai_encontrado.slice(0, -1);
    
      this.dados_editar = item

      let podeExcluir = true;

      if (!podeExcluir) {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Você deve excluir todas as dependências deste plano de conta primeiro.'
        this.store_site.alert           = true;
        return
      }

// return
//       var existe_pai = this.array_etapas.find(val => val.codigo == pai_encontrado)

//       if(!!existe_pai){
//         this.store_site.alert_cor       = "#FF0000";
//         this.store_site.alert_timeout   = 10000;
//         this.store_site.alert_msg       = 'O pai deste plano de conta deve ser excluído primeiro!'
//         this.store_site.alert           = true;
//         return
//       }
      this.dialog_excluir = true;
    },

    async grade(cod_orcamento) {
      if (!cod_orcamento)
        return
      const lo_Params = {params: {cod_orcamento : cod_orcamento}}
      let {data} = await API.get('/obras/orcamentos/etapas', lo_Params)
      this.array_etapas = data.result || []
      this.array_etapas.forEach(elem => {
        elem.text = `${elem.codigo} - ${elem.nome_etapa}`
      })
      // console.log('saiu')
      // console.log("🚀 ~ file: OrcamentoEtapa.vue:335 ~ grade ~ this.array_etapas:", this.array_etapas)
    },
    
    fechar() {
      this.$emit('update:dialog_ContasPagar', null);
    },

    // Função para enviar dados para o filho ContasPagarModal.vue e realizar a edição
    async editar(item) {
      this.dados_editar = item
      this.dados_editar.acao = 'editar'
      this.dialog_PlanoContaModal = true
    },

    async excluir(){
      const codigoEditar = this.dados_editar.cod_orcamento_etapa;
      const codigoCorrespondente = this.array_etapas.find(etapa => etapa.cod_orcamento_etapa_rel == codigoEditar) ? true : false;

      if (codigoCorrespondente) {
        this.store_site.alert_cor = "#FF0000";
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_msg = 'Essa etapa não pode ser excluída pois possui sub-etapas.';
        this.store_site.alert = true;
        return;
      }

      let ls_Res = await API.delete(`/obras/orcamentos/etapa`, { params: { ...this.dados_editar }});
      this.dialog_excluir = false;

      if (ls_Res.status == 200) {
        // Mensagem de exclusão confirmada
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500; 
        this.store_site.alert_msg       = ls_Res.data.result.result.trim();
        this.store_site.alert           = true;
        this.grade(this.cod_orcamento)
      } else {
        // Mensagem de exclusão não realizada
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Res.data.result.trim();
        this.store_site.alert           = true;
      }
    },

    async novo(){
      this.dados_editar           = {};
      this.dialog_PlanoContaModal = true;
    },

    checkMove(event) {
      // // Verifica os pais dos itens antes de permitir a movimentação
      // const draggedParent = event.draggedContext.element.codigo_pai;
      // const targetParent = event.relatedContext.element.codigo_pai;

      // // Permite a movimentação apenas se os "pais" forem iguais
      // if (draggedParent === targetParent)
      //   console.log('OK')
      // else
      //   console.log('OK')
      // return draggedParent === targetParent;
    },

    // Move os itens de lugar levando em consideração somente se ele estiver pertencendo ao mesmo pai
    async onReorder(e) {
      //console.log('---', this.array_etapas[e.oldIndex].codigo_pai, this.array_etapas[e.newIndex].codigo_pai);
      if (this.array_etapas[e.oldIndex].codigo_pai != this.array_etapas[e.newIndex].codigo_pai) {
        alert('Permitido reposicionar somente entre Itens do mesmo Pai');
        return
      }        
      let ls_CodOld = this.array_etapas[e.oldIndex].codigo;
      let ls_CodNew = this.array_etapas[e.newIndex].codigo;
      if (ls_CodOld == ls_CodNew)
        return
      this.array_etapas[e.oldIndex].codigo = ls_CodNew;
      this.array_etapas[e.newIndex].codigo = ls_CodOld;
      let lo_Old = this.array_etapas[e.oldIndex];
      let lo_New = this.array_etapas[e.newIndex];
      Vue.set(this.array_etapas, e.newIndex, lo_Old);
      Vue.set(this.array_etapas, e.oldIndex, lo_New);
      let lo_Reorder = [];
      lo_Reorder.push(lo_Old);
      lo_Reorder.push(lo_New);
 
      let ls_Res = await API.put(`/obras/orcamentos/etapa`, lo_Reorder);
      if (ls_Res.status == 200) {
        // confirmada
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500; 
        this.store_site.alert_msg       = ls_Res.data.result.result.trim();
        this.store_site.alert           = true;
        this.grade(this.cod_orcamento)
      } else {
        // não realizada
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Res.data.result.trim();
        this.store_site.alert           = true;
      }

    },

  }

}  

</script>

<style scoped>

  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .date-picker {
    max-width: 200px!important;
  }

  .table tr {
    width: 100%;
  }

  .table-row {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background: #fff;
  }

  /* .table-row:hover {
    background: #f6f6f6 !important;
  } */

  .v-btn {
    display: inline-block;
  }

  /*-----------------------------------*/
  /*------ RESPONSIVE GENERAL ------*/
  /*-----------------------------------*/
  .v-toolbar,
  .v-sheet {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px !important;
  }

  .v-toolbar__content {
    padding-left: 0px !important;
  }

  .v-toolbar__title {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .icon-action {
    font-size: 1.3rem !important;
  }

  .container-principal {
    max-width: 100%;
    margin: auto !important;
  }

.container-fundo {
  height: calc(100vh -30px) !important;
}

  .container-rounded {
    background: #fff !important;
    border-radius: 12px !important;
  }

  .data-table-impressos-fundo {
    background-color: rgb(235, 235, 239) !important;
  }

  .data-table-impressos > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-impressos {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > tbody > tr > th  {
    background-color: rgb(2, 245, 245) !important;
    height: 20px !important;
  }

  #virtual-scroll-table-tablet {
    display: none;
  }

  /* .table {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 300px !important;
  }

  .table-td {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 330px !important;
    height: 30px !important;
  } */

  .card-container {
    padding: 16px!important;
  }

  .false-filter {
    background-color:#6C87A2;
    height: 38px!important;
    min-width: 400px!important;
    /* max-width: 900px!important; */
    border-radius: 5px;
    position: relative;
  }

  .false-text {
    width: calc(100%);
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    color: #C9CBCA;
    cursor: pointer;
    overflow-x: hidden;
  }

  .false-icon {
    cursor: pointer;
  }

  .v-select__slot {
    display: none !important;
  }

  .checkbox {
    margin-bottom: 3px;
    margin-top: 1px;
  }

  /* ::v-deep .v-data-footer {
    align-content: center;
    color: var(--COR_PRINCIPAL) !important;  
    font-size:14px !important;
    height: 35px;
    padding-top: 10px !important;
  }

  ::v-deep .v-data-footer__select {
    display: none;
  }
 */

 .status_aberto_vencer {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: rgb(242, 192, 98);
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }
  .status_aberto_vencida {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: #FF5252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }  

  .status_pago {
    font-size: 12px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_inativo {
    font-size: 12px !important;
    background: #525252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_rescindido {
    font-size: 12px !important;
    background: #A2A2A2;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }


  .width-nome {
    display: block;
    min-width: 250px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  .width-parcela {
    min-width: 120px;
    white-space: nowrap;
  }

  .width-nome-customer {
    white-space: nowrap;
  }

  .width-empreendimento {
    display: block;
    min-width: 200px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  /* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O PADDING */
  .status_legend {
    font-size: 12px !important;
    font-weight: 400!important;
    padding: 2px 2px 2px 12px ;
    border-radius: 13px !important;
  }

  .status_legend:hover {
    opacity: 0.5;
  }

  .status_legend_a_vencer {
    background: rgb(242, 192, 98);
  }

  .status_legend_vencidas {
    background: #FF5252;
  }

  .status_legend_pagos {
    background: #469c47;
  }

  /*
  .status_aberto_dia_borda {
    border: 2px solid rgb(1, 168, 1) 
  }

  .status_aberto_atraso_borda {
    /* border: 2px solid rgb(1, 168, 1) 
  }
  */
  .status_a_vencer_atraso_color {
      color: red;
  }

  .status_baixado {
    background: #EF5350;
  }

  .status_pago-atraso {
    background: #FFA726;
  }

  .status_nao-encontrado {
    background: #7E57C2;
  }

  .status_msg-banco {
    background: #BDBDBD;
  }

  .status_invalido {
    background: #F44336;
  }

  /* table.v-table tbody td, table.v-table tbody th {
    height: 17px!important;
  } */

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
    background-color: rgb(245, 245, 245) !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 1.5rem !important;
    height: 35px !important;
  }

  ::v-deep .checkbox-label label {
    color: var(--COR_SECUNDARIA) !important;  
    font-size:14px !important;
  }

  .nav-link {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
  }
  

</style>


