<template>
  <div
    id="ObraContainer"
    class="container-fundo pa-0"
    v-bind:style="{ 'background-color': COR_PRINCIPAL }"
  >
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
      <!-- Título da página -->
      <v-toolbar-title
        class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0"
      >
        <!-- Botão Voltar -->
        <router-link
          :to="{ name: 'ObraConsulta' }"
          class="nav-link"
          aria-current="page"
        >
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action">
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </router-link>
        Gestão de Obra 
      </v-toolbar-title>
       <!-- TABS MOBILE -->
      <div class="d-block d-sm-none mt-n6 mb-2" tabindex="-1">
        <v-tabs
          v-if="isMobile"
          v-model="tab"
          background-color="transparent"
          dark
          class="tab-mobile mr-2 ml-2 mt-n3 pa-0"
          center-active
          slider-size="2"
          tabindex="-1"
          slider-color="#D0D0D0"
          active-class="active">
          <v-tab
            v-for="item in store_Obra.abas"
            :key="item"
            class="text-none"
            tabindex="-1">
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <!-- FIM TABS MOBILE -->
    </v-toolbar-title>
    

    <!-- Container - Cabeçalho Info. Obra / Menu Navegação / Container Abas -->
    <v-container class="container rounded-lg mt-n10 pa-0">
      <v-card elevation="0" class="card d-flex flex-column pb-0">
        <v-card-text class="card-content my-0 py-0">
          <!-- Cabeçalho Info. Obra - Avatar / Nome / Demais Dados-->
          <v-row class="d-none d-sm-block wrapper-top my-0 mt-1">
            <v-col class="col-top rounded-t-lg ma-0 pa-0">
              <v-sheet class="cabecalho d-flex align-center mx-auto px-4 py-2">
                <!-- Avatar da obra -->
                <div class="d-none d-sm-flex justify-center pr-4">
                  <v-avatar
                    rounded
                    size="80px"
                    class="grey lighten-2"
                  >
                    <span
                      v-if="!store_Obra.obra_edicao.foto_obra"
                      color="#274ABB"
                      class="headline"
                    >
                      {{ nomeIniciais(store_Obra.obra_edicao.nome_obra) }}
                    </span>
                    <img
                      v-if="store_Obra.obra_edicao.foto_obra"
                      :src="store_Obra.obra_edicao.foto_obra"
                      alt="avatar"
                    />
                  </v-avatar>
                </div>

                <!-- Nome da Obra -->
                <v-toolbar-title
                  class="text-wrap title-page body-1 d-inline-flex align-center font-weight-bold "
                  :style="{color: COR_PRINCIPAL, width: `400px !important`}"
                >
                  {{ store_Obra.obra_edicao.nome_obra }}
                </v-toolbar-title>

                <!-- divider vertical -->
                <v-divider vertical class="divisor-vertical px-2"></v-divider>
                <!-- Campo Informações Obra -->
                <v-col class="d-flex flex-column pl-4">
                  <!-- Primeira linha de informações -->
                  <v-row no-gutters class="d-flex align-center mb-2 ma-0">
                    <!-- Campo Codigo -->
                    <span
                      class="header-text body-2 mr-3 campos_style"
                    >
                      Código:
                      <span :style="{ color: COR_PRINCIPAL }">
                        {{ store_Obra.obra_edicao.cod_obra }}
                      </span>
                    </span>

                    <!-- Campo Data -->
                    <span
                      class="header-text body-2 campos_style mr-3"
                    >
                      Previsão Inicio:
                      <span v-bind:style="{ color: COR_PRINCIPAL }">
                        {{
                          store_Obra.obra_edicao.dt_previsao_inicio
                            ? formatDate(
                                store_Obra.obra_edicao.dt_previsao_inicio
                              )
                            : ""
                        }}
                      </span>
                    </span>

                    <!-- Campo Area Total -->
                    <span
                        class="header-text body-2 campos_style"
                      >
                        Área Total:
                        <span v-bind:style="{ color: COR_PRINCIPAL }">
                        {{ store_Obra.obra_edicao.area_obra }}
                      </span>
                    </span>
                  </v-row>

                  <!-- Segunda Coluna de Informações -->
                  <v-row no-gutters class="d-flex align-center ma-0">
                    <!-- Campo Valor Total -->
                    <span
                      class="header-text body-2 campos_style mr-3"
                    >
                      Valor Estimado:
                      <span :style="{ color: COR_PRINCIPAL }">
                        {{
                          store_Obra.obra_edicao.valor_previsto
                            ? formatNumber(
                                store_Obra.obra_edicao.valor_previsto
                              )
                            : ""
                        }}
                      </span>
                    </span>

                    <!-- Preço Total  -->
                    <span class="header-text body-2 campos_style mr-3">
                      Valor Realizado:
                      <span :style="{ color: COR_PRINCIPAL }">
                        {{
                          store_Obra.obra_edicao.valor_realizado
                            ? formatNumber(
                                store_Obra.obra_edicao.valor_realizado
                              )
                            : ""
                        }}
                      </span>
                    </span>

                    <!-- Situação -->
                      <span
                        class="header-text body-2 campos_style  mr-3"
                      >
                        Situação:
                        <span
                          v-if="store_Obra?.obra_edicao.situacao == 'Finalizada'"
                          class="status_ativo"
                          >{{ store_Obra?.obra_edicao.situacao }}
                        </span>

                        <!-- Em Andamento -->
                        <span
                          v-if="
                            store_Obra?.obra_edicao.situacao == 'Em Andamento'
                          "
                          class="status_pendente"
                          >{{ store_Obra?.obra_edicao.situacao }}
                        </span>

                        <!-- Parada -->
                        <span
                          v-if="store_Obra?.obra_edicao.situacao == 'Parada'"
                          class="status_cancelado"
                          >{{ store_Obra?.obra_edicao.situacao }}
                        </span>
                      </span>
                  </v-row>
                </v-col>

                <!-- divisor para o botão opções -->
                <v-divider vertical class="divisor-vertical"></v-divider>
              </v-sheet>
              <v-divider class="divisor" style="width: 100%"></v-divider>
            </v-col>
          </v-row>

          <!-- Menu coluna da esquerda -->
          <v-row class="wrapper-bottom my-0 py-0 px-0">
            <v-col
              class="col-esquerda shrink d-none d-sm-flex flex-column rounded-tl-lg rounded-bl-lg pa-0"
            >
              <!-- Menu de Navegacao -->
              <v-list
                v-show="expand"
                v-if="expand"
                class="list rounded-l-lg overflow-y-scroll pt-1"
                dense
              >
                <v-list-item-group
                  v-model="itemSelecionado"
                  active-class="bg-active"
                  mandatory
                >
                  <!-- Chama os dados da lista de store_Obra.abas e coloca separador entre eles -->
                  <template v-for="[icon, text] in store_Obra.abas">
                    <!-- SEPARADOR ------------------------------->
                    <v-divider
                      v-if="text == 'separador'"
                      :key="icon"
                      class="my-1"
                    />

                    <!-- FIM SEPARADOR ------------------------------->

                    <v-list-item
                      v-else
                      :key="icon"
                      :disabled="store_Obra.acao != 'C'"
                    >
                      <!-- Exibe icones -->
                      <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                        <v-icon class="size-icon">{{ icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content
                        v-if="text != 'separador'"
                        class="mr-n2"
                      >
                        <!-- Icone de Ok -->
                        <!-- <v-badge
                          v-if="quant && quant == 'OK'"
                          icon="mdi-check"
                          :value="quant"
                          color="green accent-9"
                          offset-x="20"
                          offset-y="18"
                        >
                        </v-badge> -->

                        <!-- icone de erro -->
                        <!-- <v-badge
                          v-else-if="quant && Number(quant) > 0"
                          :content="quant"
                          :value="quant"
                          color="red accent-1"
                          offset-x="20"
                          offset-y="18"
                        >
                        </v-badge> -->

                        <!-- Nome de cada opção da lista esquerda -->
                        <v-list-item-title class="new-font">{{
                          text
                        }}</v-list-item-title>
                        <!-- ------------------------------------ -->
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              <!-- Exibe somente os Icones quando a lista é recuada -->
              <v-list v-else dense>
                <template v-for="[icon, text] in store_Obra.abas">
                  <v-divider
                    v-if="text == 'separador'"
                    :key="icon"
                    class="my-1"
                  />

                  <v-list-item
                    v-else
                    :key="icon"
                    :disabled="store_Obra.acao != 'C'"
                  >
                    <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                      <v-icon class="size-icon">{{ icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list>

              <!-- Botão Expandir e Recuar aba esquerda -->
              <v-btn
                class="btn-expanded ma-0 pa-0"
                @click="expand = !expand"
                :style="`${expand ? '' : 'right: -10px!important;'}`"
              >
                <v-icon
                  v-if="expand == false"
                  class="size-icon-expanded ma-0 pa-0"
                  >mdi-chevron-right</v-icon
                >

                <v-icon v-else class="size-icon-expanded ma-0 pa-0"
                  >mdi-chevron-left</v-icon
                >
              </v-btn>
              <!-- fim do botãObraCadastroPessoa_DadosBasicoso Expandir e Recuar aba esquerda-->
            </v-col>

            <v-divider class="divisor-vertical" vertical></v-divider>

            <!-- COL DIREITA ------------------------------->
            <v-col
              v-if="itemSelecionado == store_Obra.abas.findIndex(aba => aba[1] === 'Dados')"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
              <ObraCadastro />
            </v-col>

            <!-- <v-col
              v-if="itemSelecionado == store_Obra.abas.findIndex(aba => aba[1] === 'Etapas')"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
              <ObraOrcamentoEtapa />
            </v-col> -->

            <v-col
              v-if="itemSelecionado == store_Obra.abas.findIndex(aba => aba[1] === 'Planilha Orçamentária')"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
              <ObraOrcamento />
            </v-col>

            <v-col
              v-if="itemSelecionado == store_Obra.abas.findIndex(aba => aba[1] === 'Cronograma')"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
              <!-- <ObraCronograma /> -->
            </v-col>

            <v-col
              v-if="itemSelecionado == store_Obra.abas.findIndex(aba => aba[1] === 'Cronograma')"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
              <!-- <ObraOrcamento /> -->
            </v-col>

            <v-col
              v-if="itemSelecionado == store_Obra.abas.findIndex(aba => aba[1] === 'Documentos')"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2"
            >
            <GedDocumentos
              :cod_obra_aux="this.$route.params.cod_obra"
              :paramsTipoDocumento="{tipo_cadastro: 'obra', tipo_cadastro_descricao: 'Documentos Obra'}"
              :paramBusca = "{cod_obra : this.$route.params.cod_obra}"
              :statusDocumento="'Aprovado'"
              @fecharModal="dialog_documentos = false" />
            </v-col>

            <v-col
              v-if="itemSelecionado == store_Obra.abas.findIndex(aba => aba[1] === 'Relatórios')"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
              <!-- <ObraOrcamento /> -->
            </v-col>

            <!-- Tasks do Mobile -------------------------------------------------------------------------------- -->
            <v-tabs-items
              v-if="isMobile"
              v-model="tab"
              class="ma-2 mb-5 mt-0"
              style="background-color: transparent"
            >
              <v-tab-item v-for="column in columns" :key="column.title">
                <div>{{ column.itemSelecionado }}</div>
              </v-tab-item>
            </v-tabs-items>
            <!-- FIM COL DIREITA ------------------------------->
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
} from "../../../services/constantes";
import store_Obra from "./store_Obra";
import store_ModalObra from "./ModalObra/store_ModalObra";
import store_usuario from "../../../store/store_usuario";
import ObraCadastro from "./ObraCadastro.vue";
import ObraOrcamentoEtapa from "./Orcamento/ObraOrcamentoEtapa.vue";
import ObraOrcamento from "./Orcamento/ObraOrcamento.vue";
import {
  formatNumber,
  maskCpfCnpj,
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";
import { baseURL } from "../../../services/API";
import GedDocumentos from "../../Ged/GedDocumentos.vue";

export default {
  components: {
    ObraCadastro,
    // ObraOrcamentoEtapa,
    ObraOrcamento,
    GedDocumentos
  },

  data() {
    return {
      store_Obra       : store_Obra,
      nomeIniciais     : nomeIniciais,
      store_usuario    : store_usuario,
      store_ModalObra  : store_ModalObra,

      COR_PRINCIPAL    : COR_PRINCIPAL,
      COR_SUBTITULO    : COR_SUBTITULO,
      COR_SECUNDARIA   : COR_SECUNDARIA,
      baseURL          : baseURL,

      formatDate       : formatDate,
      formatNumber     : formatNumber,
      maskCpfCnpj      : maskCpfCnpj,

      expand: true,

      // dados: {
      //   nome: "Fernando Garcia",
      //   telefone: "(16) 99999-9999",
      //   email: "john@vuetifyjs.com",
      // },

      tab: null,

      itemSelecionado: {},

      columns: [],

    };
  },

  computed: {
    ImagemCapaCaminhoCompleto: function () {
      var img = "";
      // console.log(!!this.store_Obra.obra_edicao.vendas_imovel?.imovel_imagem[0]);
      if (!!this.store_Obra.obra_edicao?.vendas_imovel?.imovel_imagem[0]) {
        img =
          this.baseURL +
          "imagens/imoveis/fotos/empresa_" +
          this.store_usuario.user.cod_usuario +
          "/" +
          this.store_Obra.obra_edicao?.vendas_imovel?.imovel_imagem[0]
            .imagem_caminho;
      }
      return img;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },

  async created() {
    var lo_params = { cod_obra: this.$route.params.cod_obra };
    if (lo_params.cod_obra) {
      this.store_Obra.acao = "C";
      this.store_Obra.obra_edicao = {};
      const lo_Res = await this.store_Obra.ObraGet(lo_params);
      if (!!lo_Res) {
        // console.log("lo_Res", lo_Res);
        this.store_Obra.obra_edicao = { ...lo_Res };
      }
    } 
    // Inclusão 
    else {
      this.store_Obra.acao = "I";
      this.store_Obra.obra_edicao = {
        cod_obra: null,
        nome_obra: null,
        cno: null,
        area_obra: null,
        obra_cliente: {"cod_pessoa":null, "nome_pessoa": ''},
        obra_empreitada: {"cod_pessoa": null, "nome_pessoa": ''},
        dt_previsao_inicio: null,
        dt_previsao_termino: null,
        dt_realizacao_inicio: null,
        dt_realizacao_termino: null,
        valor_previsto: null,
        valor_realizado: null,
        situacao: null,
        endereco_obra: [],
        endereco_cobranca: [],  
        obra_deposito: [],
      };
    }
  },

  async mounted() {
  },

  methods: {
    tamanho() {
      if (this.expand) return "180px";
      else return "1px";
    },

  },
};
</script>

<style scoped lang="scss">
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 17px !important;
}

@media (max-width: 599px) {
  .title-page {
    width: 100% !important;
  }

  .text-opacity {
    opacity: 0.7;
  }

  .tab-mobile {
    margin-left: -44px;
  }
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #fff !important;
  opacity: 0.8;
}

.nav-link {
  text-decoration: none;
}

.container {
  max-width: 100% !important;
  height: calc(100vh - 110px) !important;
}

@media (max-width: 599px) {
  .container {
    max-width: 100% !important;
    height: calc(100vh - 160px) !important;
    margin-top: -4px !important;
  }
}

.card {
  height: 100% !important;
  border-radius: 8px;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA) !important;
}

.card-content {
  height: 100% !important;
}

.wrapper-top {
  height: 105px !important;
}

.header-text {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
}

.wrapper-bottom {
  height: calc(100% - 105px) !important;
}

.divisor {
  height: 100%;
}

@media (max-width: 599px) {
  .wrapper-bottom {
    width: 100%;
    flex-direction: column !important;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .divisor {
    display: none;
  }
}

.col-top {
  width: 100vw;
}

.col-esquerda {
  max-width: 300px !important;
  height: 100%;
  position: relative;
  background-color: #f7f7f7;
  margin-top: -2px;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.size-icon {
  font-size: 20px;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  height: 100%;
  width: 70%;
}

@media (max-width: 599px) {
  .col-direita {
    padding-right: 4px !important;
    padding-left: 0px !important;
    width: 100%;
  }
}

.list {
  max-width: 300px !important;
  min-width: 240px !important;
  margin-top: -2px;
  color: var(--COR_SUBTITULO);
}

// .v-list-item-content
// .v-list-item-title {
//   color: #FF0000;
//   background-color: #FF0000;
//   padding-right: 5px!important;
// }

// .active_list .v-list-group .v-list-item--active {
//   color: #0000FF !important;
//   background-color: #FF0000;
// }

// .v-list-tile {
//   color: #FF0000;
//   background-color: #FF0000;
// }

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff !important;
}

.bg-active {
  background-color: var(--COR_PRINCIPAL) !important;
  color: white !important;
}

.cabecalho {
  background-color: #f7f7f7;
}

@media (min-width: 900px) {
  .cabecalho {
    max-height: 100px;
    height: 100px !important;
    min-height: 100px;
  }
}
.divisor-vertical {
  // display: none;
  height: 100% !important;
  position: relative;
}

.status_ativo {
  font-size: 12px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 12px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 12px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}
.custom-divider {
  height: 50px; /* Ajuste o valor conforme necessário */
}

.col-esquerda-collapsed {
  width: 50px; /* Ajuste conforme necessário */
  overflow: hidden;
}

.col-esquerda-expanded {
  width: 300px; /* Ajuste conforme necessário */
}

.campos_style{
  font-weight: bold;
  color: rgba(45, 118, 160, 0.808)
}
</style>
