<template>
  <div id="ObraOrcamento">
    <!-- Cabeçalho - Titulo / Navegação Abas-->
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between font-primary ml-4">
      <p 
        class="font-primary font-weight-bold font-weight-medium body-1 ma-0"
        v-bind:style="{ color: COR_SUBTITULO }">
        Planilha Orçamentária
      </p>
      <div>
        <v-tabs
          background-color="transparent"
          v-model="tipoSelecionado"
          :slider-color="COR_PRINCIPAL" 
          :color="COR_PRINCIPAL"
          active-class="active"
          slider-size="3"
          tabindex="-1"
        >
          <v-tab 
            v-for="tipo in tipos_planilha_orcamentaria"
            style="width: 250px; max-width: none;"
            class="text-none rounded"
            tabindex="-1"
            :key="tipo"
          >
            {{ tipo }}
          </v-tab>
        </v-tabs>
      </div>
    </v-toolbar-title>

    <v-card class="container-card" outlined>
      <v-card-title class="pa-0">
        <v-tabs
          width="100%"
          v-model="itemSelecionado"
          background-color="transparent"
          light
          center-active
          slider-size="3"
          tabindex="-1"
          :slider-color="COR_PRINCIPAL"
          active-class="active"
          :color="COR_PRINCIPAL"
        >
          <v-tab
            v-for="item in abas_planilha_orcamentaria"
            :key="item"
            class="text-none"
            tabindex="-1"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-container 
          class="pa-0 ma-0"
          v-if="itemSelecionado == 0"
          :key="key_OrcamentoEtapa">
          <ObraOrcamentoEtapa
            :cod_orcamento.sync="cod_orcamento" />
        </v-container>
        <v-container
          class="pa-0 ma-0"
          v-if="itemSelecionado == 1">
          <ObraOrcamentoItem />
        </v-container>
      </v-card-text>
    </v-card>

    <!-- modal cadastrar insumo -->
    <v-container v-if="store_ModalObra.dialog_cadastrar_insumo">
      <v-dialog
        v-model="store_ModalObra.dialog_cadastrar_insumo"
        transition="dialog-bottom-transition"
        max-width="440px"     
        persistent          
        >
        <v-card style="height: 500px">
          <ModalCadastrarInsumo
            @close="buscaInsumos"/>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import store_ModalObra from '../ModalObra/store_ModalObra'

import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  COR_SECUNDARIA_2,
  COR_BACKGROUND
} from "../../../../services/constantes";
import { formatDate, formatNumber } from "../../../../services/funcoes";
import DatePicker from "../../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { API } from "../../../../services/API";
import VuetifyMoney from '../../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue';
import ModalCadastrarInsumo from "../../Insumo/ModalInsumo/ModalCadastrarInsumo.vue";
import ObraOrcamentoEtapa from "./ObraOrcamentoEtapa.vue";
import ObraOrcamentoItem from "./ObraOrcamentoItem.vue";
import Draggable from 'vuedraggable';

export default {
  name: "ObraOrcamento",
  components: {
    ModalCadastrarInsumo,
    ObraOrcamentoEtapa,
    ObraOrcamentoItem,
  },

  data: () => ({

    store_ModalObra   : store_ModalObra,

    COR_PRINCIPAL     : COR_PRINCIPAL,
    COR_SECUNDARIA    : COR_SECUNDARIA,
    COR_SUBTITULO     : COR_SUBTITULO,
    COR_SECUNDARIA_2  : COR_SECUNDARIA_2,
    COR_BACKGROUND    : COR_BACKGROUND,

    formatNumber              : formatNumber,

    tipoSelecionado           : '',
    itemSelecionado           : 0,
    abas_planilha_orcamentaria : ["Etapas", "Items"],
    tipos_planilha_orcamentaria : ["Previsto", "Realizado"],

    dados               : {},
    cod_orcamento       : null,
    key_OrcamentoEtapa  : 0
  }),
  created () {
    this.abreOrcamento(this.$route.params.cod_obra, 'Aberto');
    // Seta os privilegios dos elementos da apícãõ inteira
    // this.store_usuario.priv_seta_privilegios_aplicacao(this.$options.name);
  },
  mounted() {    

  },

  // Create data example
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  watch: {
  },
  methods: {
    async abreOrcamento(cod_obra, tipo) {
      let lo_Obra = {params: {cod_obra: cod_obra, 
                              situacao: tipo
                            }}
      let {data} = await API.get('/obras/orcamento', lo_Obra);
      this.dados = data.result;
      this.cod_orcamento = this.dados.cod_orcamento;
      this.key_OrcamentoEtapa++
      // this.array_etapas = data.result || []
      // this.array_etapas.forEach(elem => {
      //   elem.text = `${elem.codigo} - ${elem.nome_etapa}`
      // })
    },

    // close () {
    //   setTimeout(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   }, 300)
    // },

    cancelIndex(idx) {
      if (this.editedIndex == -1)
        return
      if (this.editedItem.acao == 'I')
        this.dados.splice(idx, 1);
      this.editedIndex = -1;
      this.editedItem = {
        acao            : null,
        cod_insumo      : null,
        tipo            : 'Item',
        codigo          : null,
        nome_insumo     : null,
        categoria       : null,
        periodo         : null,
        qtde_unit       : null,
        unidade         : null,
        valor_unitario  : null,
        total           : null,
      };
    },
    addNew(idx) {
      // O objeto que você deseja adicionar
      let newObject = {
        acao            : 'I',
        cod_insumo      : null,
        tipo            : 'Item',
        codigo          : null,
        nome_insumo     : null,
        categoria       : null,
        periodo         : null,
        qtde_unit       : null,
        unidade         : null,
        valor_unitario  : null,
        total           : null,
      };
      // Adicionando o novo objeto na posição 6 (índice 5)
      this.dados.splice(idx, 0, newObject);
      this.editedIndex  = idx;
      this.editedItem   = {...newObject};      
    },
    SaveItem(index) {
      let newObject = {
        cod_insumo      : this.dados.length+1,
        tipo            : this.editedItem.tipo,
        codigo          : '1.5',
        nome_insumo     : this.editedItem.nome_insumo,
        categoria       : this.editedItem.categoria,
        periodo         : null,
        qtde_unit       : this.editedItem.qtde_unit,
        unidade         : this.editedItem.unidade,
        valor_unitario  : this.editedItem.valor_unitario,
        total           : this.editedItem.total,
      };
      Object.assign(this.dados[index], newObject)
      this.editedIndex = -1;
      this.editedItem = {
        acao            : null,
        cod_insumo      : null,
        tipo            : 'Item',
        codigo          : null,
        nome_insumo     : null,
        categoria       : null,
        periodo         : null,
        qtde_unit       : null,
        unidade         : null,
        valor_unitario  : null,
        total           : null,
      };
      this.addNew(index+1);
      this.foco('#nome_insumo');
    },
    prepararCadastroInsumo(){
      this.store_ModalObra.acao = "I";
      this.store_ModalObra.cadastro_insumo = {}
      this.cadastrarInsumo();
    },

    cadastrarInsumo() {
      this.store_ModalObra.step = "ModalCadastrarInsumo";
      this.store_ModalObra.dialog_cadastrar_insumo = true;
    },
    foco(elem_id) {
      this.$nextTick(() => {
        let elem_focus = document.querySelector(elem_id)
        elem_focus?.focus();
      });      
    },
    focusNextField() {
      const form = this.$refs.form.$el;
      const focusableElements = form.querySelectorAll(
        'input, select, textarea, button, [tabindex]:not([tabindex="-1"])'
      );

      // Find the currently focused element
      const currentIndex = Array.prototype.indexOf.call(
        focusableElements,
        document.activeElement
      );

      // Focus the next element, if it exists
      if (currentIndex !== -1 && currentIndex + 1 < focusableElements.length) {
        focusableElements[currentIndex + 1].focus();
      }
    }
  },
  
};
</script>

<style scoped>
#ObraOrcamento {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

.container-card {
  box-sizing: border-box;

}

.custom-text-field .v-input__control {
  padding: 0 !important; /* Remove padding do controle de entrada */
}

.custom-text-field .v-input__slot {
  padding: 0 !important; /* Remove padding do slot de entrada */
}

.custom-text-field .v-text-field__slot {
  padding: 0 !important; /* Remove padding do slot específico do v-text-field */
}

.custom-text-field input {
  padding: 0 !important; /* Remove padding do campo de entrada */
  margin: 0 !important;  /* Remove qualquer margem adicional */
}

.custom-text-field .v-label {
  padding: 0 !important; /* Remove padding do label */
  margin: 0 !important;  /* Remove qualquer margem adicional */
}

.custom-text-field .v-messages__wrapper {
  padding: 0 !important; /* Remove padding das mensagens */
  margin: 0 !important;  /* Remove qualquer margem adicional */
}

/* ------------------------------------------------------------------------------- */

.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #555;
  color: #fff;
}

.w-100 {
  width: 100%
}

.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif !important;
}

.icon-filter {
  font-size: 20px;
}

table tr {
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.v-btn {
  display: inline-block;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.td-borda {
  border-right: 1px solid #EEEEEE;
  padding: 3px;
}

thead th:hover {
  background-color: #F3F3F3;
}

thead th {
  padding: 3px;
  font-weight: 600;
}

.z-index-max {
  z-index: 20 !important;
}

.custom-button-start {
  width: 30px !important;
  height: 30px !important;
  margin: 10px !important;;
  padding: 0 !important;
  object-position: absolute;
  top: 0px !important;
  left: 0px !important;
  z-index: 20 !important;
}

.custom-button-top {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  object-position: absolute;
  left: 0px !important;
  top: -5px !important;
  z-index: 20 !important;
}

.custom-button-bottom {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  object-position: absolute;
  left: 0px !important;
  bottom: -5px !important;
  z-index: 20 !important;
}

.custom-button-move {
  width: 25px !important;
  height: 25px !important;
  object-position: absolute;
  left: 10px !important;
  top: 0px !important;
  z-index: 20 !important;
}

.custom-button-right {
  width: 20px !important;
  height: 20px !important;
  object-position: absolute;
  right: 10px !important;
  top: 0px !important;
  z-index: 20 !important;
}

tr {
  height: 35px !important;
}

/* tr:hover {
  background-color: #F3F3F3;
} */

.custom-autocomplete .v-input__control {
  min-height: 30px; /* Ajuste a altura mínima conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-input__slot {
  height: 30px; /* Ajuste a altura do slot conforme necessário */
  padding: 0px; /* Ajuste o padding conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-select__selections {
  line-height: 30px; /* Ajuste a altura da linha conforme necessário */
}

.custom-autocomplete input {
  height: 30px; /* Ajuste a altura do input conforme necessário */
  line-height: 30px; /* Ajuste a altura da linha do input conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-label {
  top: -20px; /* Ajuste a posição do label conforme necessário */
  font-size: 12px; /* Ajuste o tamanho da fonte do label conforme necessário */
  padding: 0px;
}

.custom-autocomplete .v-select__selections > div {
  margin-top: -5px; /* Ajuste a margem superior conforme necessário */
  padding: 0px;
}

.text-nome:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--COR_SUBTITULO);
}

/* ::v-deep .theme--light.v-input input, .theme--light.v-input textarea .v-input .v-input__slot div.v-text-field__slot fieldset .input input  {          */
  /* border: 1px solid rgba(255, 0, 0, 0.2) !important; */
  /* background-color: rgba(255, 0, 0, 0.2) !important; */
  /* margin-left: -9px !important; */
  /* height: 30px !important;; */
  /* width: 100% !important;; */
/* } */

::v-deep .theme--light.v-input input {
  border: 0px solid rgba(255, 0, 0, 0.2) !important;
  background-color: rgba(0, 255, 0, 0.1) !important;
}

/*
::v-deep .v-application--is-ltr .v-text-field--reverse input {
  padding: 0px !important;
} */

.active {
  background-color: #FFFFFF;
}
</style>
