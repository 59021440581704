import Vue from "vue";
import { API } from "../../../../services/API";
import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../../../services/funcoes";

function initialState() {
  return {

    dialogFiltroAvancado  : false,
    dialogUnidades        : false,
    dialog_incluir_insumo : false,

    incluir_insumo : {},
    dados       : [],
    paginas     : 0,
    filtro: {

      // Filtro na Tela de Consulta --------
      cliente_nome        : null,
      cod_empreendimento  : null,
      empreendimento      : null,

      quadra              : null,
      lote                : null,
      num_proposta        : null,

      // Vencimento (data e valor)
      parc_dt_inicial       : null,
      parc_dt_final         : null,
      parc_valor_inicial    : null,
      parc_valor_final      : null,
  
      // Pagamento (data e valor)
      parc_dt_pagto_inicial     : null,
      parc_dt_pagto_final       : null,
      parc_valor_pagto_inicial  : null,
      parc_valor_pagto_final    : null,
  
      // Filtro Avancado --------------------
      situacao: null,
      proprietario: null,
      contrato: null,
      num_cobranca: null,
      tipo_parcela: null,
      parcela: null,
      tipo_impressao: null,
      bloqueados: null,
      reajustados: null,
      proposta_inicial: null,
      proposta_final: null,
      data_contrato: null,
      impressao_inicial: null,
      impressao_final: null,
    },

    opcoesFormaDePagamento: []
  }
}

var vm_store_Orcamento = new Vue({

  data () {
    return initialState();
  },

  methods: {
    // routes.put('/api/erp/financeiro/contaspagar_editar/:cod_empreendcobranca', AuthController.authenticated, ContasPagarController.ContasPagar_editar);
    // routes.put('/api/erp/contas_a_receber/contaspagar_estornar/:cod_empreendcobranca', AuthController.authenticated, ContasPagarController.ContasPagar_estornar);
    // routes.put('/api/erp/contas_a_receber/contaspagar_pagar/:cod_empreendcobranca', AuthController.authenticated, ContasPagarController.ContasPagar_pagar);
    // routes.put('/api/erp/contas_a_receber/contaspagar_excluir/:cod_empreendcobranca', AuthController.authenticated, ContasPagarController.ContasPagar_excluir);   

    async ContasPagarNovo(body){
      // const lo_Param = JSON.stringify(body)
      // console.log('body',body)
      const resposta = await API.post('/erp/contaspagar/', body);
      return resposta;
    },
    
    async ContasPagarEstornar(body) {
      const resposta = await API.put(`/erp/contaspagar/contaspagar_estornar/${body.cod_empreendcobranca}`, JSON.stringify(body));
      return resposta;
    },

    async ContasPagarPagar(body){
      // console.log('contasPagarPagar')
      const resposta = await API.put(`/erp/contaspagar/contaspagar_pagar/${body.cod_empreendcobranca}`, JSON.stringify(body));
      return resposta;
    },

    async ContasPagarEditar(body){
      // console.log('body', body)
      const resposta = await API.put(`/erp/contaspagar/${body.cod_empreendcobranca}`, JSON.stringify(body));
      return resposta;
    },

    async ContasPagarFormasDePagamento () {
      const { data: { result }} = await API.get('/erp/contaspagar_tipo_pagamento')

      this.opcoesFormaDePagamento = result.data.map(tipo => ({ 
        id: tipo.cod_fin_tipo_pagamento, 
        text: tipo.tipopagamento_descricao, 
        value: tipo.cod_fin_tipo_pagamento 
      }))
    },

    async ContasPagarFormasDePagamentoNovo(body) {
      const resposta = await API.post(`/erp/contaspagar/contaspagar_forma_de_pagamento`, JSON.stringify(body));
      // console.log('resposta',resposta)
      return resposta;
    },

    async ContasPagarExcluir(id){
      try {
        let resposta = null

        if (Array.isArray(id)) {
          resposta = await API.delete(`/erp/contaspagar/${JSON.stringify(id)}`)
        } else {
          resposta = await API.delete(`/erp/contaspagar/${id}`);
        }

        // console.log(resposta)
        return resposta;
      } catch (e) {
        console.log(e)
      } 
      
    },

    async ContasPagarImpressos(item) {
      // console.log('store', item)
      const lo_Param = {cod_empreendcobranca: item}
      const resposta = await API.get(`/erp/contaspagar/contaspagar_impressos`, {params: lo_Param});
      return resposta;
    },

    async ContasPagarImpressosExcluir(item) {
      // console.log('store', item)
      const lo_Param = {cod_empreendcobranca_impressao: item}
      const resposta = await API.put(`/erp/contaspagar/contaspagar_impressos_excluir`, {params: lo_Param});
      return resposta;
    },

    async ContasPagarImpressosBaixar(item) {
      // console.log('store', item)
      const lo_Param = {cod_empreendcobranca_impressao: item}
      const resposta = await API.put(`/erp/contaspagar/contaspagar_impressos_baixar`, {params: lo_Param});
      return resposta;
    },

    async ContasPagarBuscaProposta(item) {
      // console.log('store', item)
      const lo_Param = {cod_empreendvenda: item}
      const resposta = await API.get(`/erp/contaspagar/contaspagar_proposta_buscar`, {params: lo_Param});
      // console.log('resposta',resposta)
      return resposta;
    },

    async getCheque (cod_pessoa) {
      const resposta = await API.get(`/erp/contaspagar_cheque/${cod_pessoa}`);

      // console.log('cheques ->', resposta)
    },
    
    resetData() { 
      Object.assign(this.$data, initialState());
    },
    
    async InsumosGet(p_params) {
      const resp = await API.get("/insumos", { params: { ...p_params } });
      if (resp) {
        this.dados = [];
        if (resp.data.result !== 'Não possui dados') {
          this.dados = resp.data.result
          this.count_dados = resp.data.result.length;
          return resp.data.result
        }
      }
    },

    // Get de um insumo específico
    async InsumoGet(p_params) {
      const resp = await API.get(`/insumos/${p_params.cod_insumo}`);
      return resp.data.result
    },
    async InsumoPost(body) {
      const resp = await API.post(`/insumos`, JSON.stringify(body));
      return resp;
    },

    async InsumoPut(p_params) {
      const resp = await API.put(`/insumos/${p_params.cod_insumo}`, JSON.stringify(p_params))
      return resp;
    },

    async InsumoDelete(p_params) {
      const resp = await API.delete(`/insumos/${p_params.cod_insumo}`);
      return resp
    },
      
  },

})

export default vm_store_Orcamento
